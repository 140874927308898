import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import apollo from './lib/apollo';
import './App.scss';
import RouteList from './components/Routes';
import MaterialUiStyle from './styles/materialUIStyle';
import Toaster from './components/Toaster/Toaster';

const App = () => {
  const client = apollo();
  return (
    <ApolloProvider client={client}>

      <MaterialUiStyle />
      <Router>
        <RouteList />
      </Router>
      <Toaster />

    </ApolloProvider>
  );
};

export default App;
