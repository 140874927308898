import { gql } from '@apollo/client';

const LOGOUT_USER = gql`
query Logout($refreshToken: String!) {
    logout(refreshToken: $refreshToken) {
      message
      type
    }
  }
`;

export default LOGOUT_USER;
