import { gql } from '@apollo/client';

const GET_TEAMS = gql`
query GetTeams {
  getTeams {
    id
    name
    created_by
    updated_by
    created_at
    updated_at
    teamMembersCount
    teamMembers {
      user {
        id
        first_name
        last_name
        email
      }
    }
  }
}
`;

export default GET_TEAMS;
