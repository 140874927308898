/* eslint-disable max-len */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Dialog, DialogContent, DialogContentText, Divider, List, ListItem, ListItemText } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ClearIcon from '@mui/icons-material/Clear';
import { useMutation } from '@apollo/client';
import AuthContext from '../../../context/AuthContext';
import ADD_MEMBER from './addMemberMutation';
import CREATE_TEAM from './createTeamMutation';
import Toaster from '../../../components/Toaster/Toaster';
import LoadingOverlay from '../../../components/LoadingOverlay';

const CustomTypography = styled(Typography)`
&.MuiTypography-root{
    font-family: Lato;
    font-weight: 500;
    font-size: 17px;
    color: #FFFFFF;
  }
`;

const CustomButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    color: #007AFF;
    text-transform: none;
    border-radius: 95px; 
    width: 318px;
    height: 56px;
    background-color: rgb(0, 122, 255, 0.1)
  }
`;
const CustomDialogContentText = styled(DialogContentText)`
&.MuiDialogContentText-root{
    font-family: Lato;
    font-weight: 500;
    font-size: 17px;
    color: #171617;
    text-align: center;
  }
`;
const CustomDialogButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    color: #1D1C1D;
    text-transform: none;
    &:hover,
    &:focus {
    color: #1D1C1D;
    font-weight: 700;
    }
    &:active {
    color: #1D1C1D;
    font-weight: 700;
    }
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    color: #006CE3;
    text-transform: none;
  }
`;

const CustomTextField = styled(TextField)`
&.MuiTextField-root {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  color: #1A191A;
  background-color: #FFFFFF;
  text-transform: none;
  max-width: calc(100% - 100px);
  width: 100%;
}
`;

const CustomListItemText = styled(Typography)`
&.MuiTypography-root{
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
  }
`;
const CustomInputLabel = styled(InputLabel)`
&.MuiInputLabel-root{
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    color: #A8AEB8;
  }
`;

const useStyles = makeStyles({
  input: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
});

const dialogStyle = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '45px !important',
      paddingRight: '45px !important',
      paddingTop: '23px !important',
      paddingBottom: '27px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
      paddingTop: '18px !important',
      paddingBottom: '20px !important',
    },
  },
  discard: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '19px !important',
      paddingRight: '19px !important',
      paddingTop: '12px !important',
      paddingBottom: '17px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '12px !important',
      paddingRight: '12px !important',
      paddingTop: '8px !important',
      paddingBottom: '10px !important',
    },
  },
  save: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '27px !important',
      paddingRight: '31px !important',
      paddingTop: '12px !important',
      paddingBottom: '17px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '18px !important',
      paddingRight: '18px !important',
      paddingTop: '8px !important',
      paddingBottom: '10px !important',
    },
  },
}));

const CustomIconButton = styled(IconButton)`
&.MuiIconButton-root{
    font-family: Lato;
    font-weight: 400;
    color: #FFFFFF;
  }
`;

const Newteam = () => {
  const { addTeamMember, addMember, dispatch } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [addMembers] = useMutation(ADD_MEMBER);
  const [createTeam] = useMutation(CREATE_TEAM);
  const [toastMsg, setToastMsg] = React.useState('');
  const [Loading, setIsLoading] = React.useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dialogClass = dialogStyle();
  const [items, setItems] = React.useState(addMember);
  const [toast, setToast] = React.useState({
    open: '',
    message: '',
  });

  const ref = React.useRef(null);
  const joinMemberToTheTeam = async () => {
    navigate('/add-member');
    await dispatch({ type: 'ADD_TEAM_MEMBER', payload: ref.current.values.name });
    await dispatch({ type: 'ADD_MEMBER', payload: addMember });
  };

  const teamNavigate = async () => {
    if (items.length > 0 || ref.current.values.name) {
      setOpen(true);
    } else {
      await dispatch({ type: 'ADD_TEAM_MEMBER', payload: '' });
      await dispatch({ type: 'ADD_MEMBER', payload: [] });
      await dispatch({ type: 'TAB_VALUE', payload: 0 });
      navigate('/users');
    }
  };

  const onteamMemberDelete = (index) => async () => {
    const newData = items.filter((item) => item.id !== index.id);
    await dispatch({ type: 'ADD_MEMBER', payload: newData });
    setItems(newData);
  };

  const teamSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const initialValues = {
    name: '' || addTeamMember,
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast((prevstate) => ({
      ...prevstate,
      open: false,
    }));
    if (toastMsg === 'SUCCESS') {
      navigate('/users');
    }
  };
  const discardExit = async () => {
    await dispatch({ type: 'EDIT_TEAM', payload: [] });
    navigate('/users');
  };

  const handleDialogClose = async () => {
    setOpen(false);
  };
  return (
    <>
      <LoadingOverlay open={Loading} />
      <Grid container>
        <Grid container sx={{ backgroundColor: '#31132C', height: '51px' }}>
          <Grid item xl={5.7} lg={5.5} xs={5.2} md={5.3} sm={5.3}>
            <CustomIconButton
              sx={{ marginLeft: 2, marginTop: 0.5, marginBottom: 2 }}
              onClick={teamNavigate}
            >
              <ArrowBackIosIcon sx={{ width: '25px', height: '25px' }} />
            </CustomIconButton>
          </Grid>

          <Grid item xl={2} lg={2} xs={6} md={6.7} sm={2}>
            <CustomTypography sx={{ marginTop: 1.7, marginBottom: 2 }}>
              New Team
            </CustomTypography>
          </Grid>

        </Grid>

        <Grid width="100%" style={{ height: 'calc(100vh - 51px)', overflowX: 'hidden' }}>
          <Formik
            innerRef={ref}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={teamSchema}
            onSubmit={async (values) => {
              try {
                let variables = {
                  name: values.name,
                };
                setIsLoading(true);
                const { data: { createTeam: { id } }, loading } = await createTeam({
                  variables,
                });
                if (!loading) {
                  setIsLoading(false);
                }
                if (id) {
                  variables = {
                    input: {
                      user_ids: items.map((user) => parseInt(user.id, 10)) || [],
                      team_id: parseInt(id, 10),
                    },
                  };
                  addMembers({
                    variables,
                  });
                }
                setToast((prevstate) => ({
                  ...prevstate,
                  message: 'Team added successfully',
                  open: true,
                  type: 'success',
                }), setToastMsg('SUCCESS'));
                await dispatch({ type: 'TAB_VALUE', payload: 0 });
              } catch (error) {
                setIsLoading(false);
                const errorMessage = error.networkError.result.errors.map((e) => e.message).toString().slice(0, 34);
                const result = (errorMessage) === ('Team with given name already exist');
                setToast((prevstate) => ({
                  ...prevstate,
                  message: result === true
                    ? 'Team with given name already exist'
                    : 'Something went wrong!',
                  open: true,
                  type: 'error',
                }));
              }
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, touched, errors, values }) => (
              <Grid container direction="column">
                <Grid container direction="column" style={{ marginLeft: '20px', marginTop: '20px' }}>
                  <Grid container direction="row" style={{ borderBottom: '1px solid #E4E6E9' }}>
                    <Grid container className={classes.input}>
                      <CustomInputLabel sx={{ marginRight: '15px', marginTop: '6.5px', marginBottom: '22px' }}>Name</CustomInputLabel>
                      <CustomTextField
                        sx={{ marginTop: '-1px', marginBottom: '22px' }}
                        type="text"
                        name="name"
                        id="name"
                        value={values.name || ''}
                        error={errors.name && touched.name}
                        helperText={touched.name && errors.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>

                  <Grid direction="row" sx={{ marginTop: '20px', marginBottom: '5px' }}>
                    <CustomInputLabel sx={{ marginRight: '30px' }}>Members</CustomInputLabel>
                  </Grid>

                  <Grid width="98%">
                    {items !== 'null' && (items
                    && items.map((teamMember) => (
                      <List sx={{ marginRight: '30px' }}>
                        <ListItem style={{ backgroundColor: 'rgb(97, 97, 97, 0.1)' }}>
                          <ListItemText>
                            <CustomListItemText>{`${teamMember.first_name} ${teamMember.last_name}`}</CustomListItemText>
                          </ListItemText>
                          <Button onClick={onteamMemberDelete(teamMember)}>
                            <ClearIcon style={{ color: '#171617' }} />
                          </Button>
                        </ListItem>
                      </List>
                    )))}

                  </Grid>

                  <Grid direction="row">
                    <StyledButton sx={{ marginLeft: '-10px', marginBottom: '35px' }} onClick={joinMemberToTheTeam}>+ Add Members</StyledButton>
                  </Grid>
                  <Grid
                    sx={{ marginBottom: '20px' }}
                    container
                    justifyContent="center"
                  >
                    <CustomButton onClick={() => handleSubmit()} style={{ marginRight: '35px' }}>Create Team</CustomButton>
                  </Grid>
                </Grid>
                <Grid>
                  <Dialog
                    PaperProps={{
                      style: {
                        borderRadius: '11px',
                      },
                    }}
                    open={open}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <Grid style={{ backgroundColor: '#F7F7F8' }}>
                      <DialogContent className={dialogClass.root}>
                        <CustomDialogContentText>Are you sure you want to</CustomDialogContentText>
                        <CustomDialogContentText>exit without saving?</CustomDialogContentText>
                      </DialogContent>
                    </Grid>
                    <Divider />
                    <Grid container>
                      <CustomDialogButton
                        className={dialogClass.discard}
                        onClick={discardExit}
                      >
                        Discard & Exit

                      </CustomDialogButton>
                      <Divider orientation="vertical" flexItem />
                      <CustomDialogButton
                        className={dialogClass.save}
                        onClick={() => handleSubmit()}
                      >
                        Save & Exit

                      </CustomDialogButton>
                    </Grid>
                  </Dialog>
                </Grid>
              </Grid>
            )}
          </Formik>
          <Grid item>
            <Toaster onClose={handleClose} {...toast} />
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};
export default Newteam;
