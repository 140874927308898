import { gql } from '@apollo/client';

const GET_TEAMS_BY_ID = gql`
query GetTeamById($teamId: Int!) {
    getTeamById(teamId: $teamId) {
      id
      name
      teamMembers {
        id
        first_name
        last_name
      }
    }
  }
  `;

export default GET_TEAMS_BY_ID;
