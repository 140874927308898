import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const typographyTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: 16,
      color: '#1D1C1D',
    },
  },
});

const propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const defaultProps = {
  children: '',
};
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ justifyContent: 'center' }}
    >
      {value === index && (
        <Box>
          <Box sx={{ ml: 1 }}>
            <ThemeProvider theme={typographyTheme}>{children}</ThemeProvider>
          </Box>
        </Box>
      )}
    </div>

  );
};

TabPanel.propTypes = propTypes;
TabPanel.defaultProps = defaultProps;
export default TabPanel;
