import * as React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLazyQuery } from '@apollo/client';
import { Divider, Grid, IconButton, ListItemButton, Typography, Checkbox, DialogContent, DialogContentText, Dialog, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import GET_TEAMS from '../../../components/Tabs/Team/getTeamGraphQuery';
import AuthContext from '../../../context/AuthContext';
import LoadingOverlay from '../../../components/LoadingOverlay';

const CustomIconButton = styled(IconButton)`
&.MuiIconButton-root{
    font-family: Lato;
    font-weight: 400;
    color: #FFFFFF;
  }
`;

const StyledButton = styled(Checkbox)`
    &.MuiCheckbox-root {
      &.Mui-checked {
        color: #006CE3;
    }
    color: transparent;
}
`;

const CustomTypography = styled(Typography)`
&.MuiTypography-root{
    font-family: Lato;
    font-weight: 500;
    font-size: 17px;
    color: #FFFFFF;
  }
`;
const CustomButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    color: #1D1C1D;
    text-transform: none;
    &:hover,
    &:focus {
    color: #1D1C1D;
    font-weight: 700;
    }
    &:active {
    color: #1D1C1D;
    font-weight: 700;
    }
  }
`;
const CustomListItemText = styled(ListItemText)`
&.MuiListItemText-root{
   text-transform:capitalize;
   margin-left: -10px;
  }
`;
const CustomDialogContentText = styled(DialogContentText)`
&.MuiDialogContentText-root{
    font-family: Lato;
    font-weight: 400;
    font-size: 17px;
    color: #171617;
    text-align: center;
  }
`;

const dialogStyle = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '45px !important',
      paddingRight: '45px !important',
      paddingTop: '23px !important',
      paddingBottom: '27px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
      paddingTop: '18px !important',
      paddingBottom: '20px !important',
    },
  },
  discard: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '19px !important',
      paddingRight: '19px !important',
      paddingTop: '12px !important',
      paddingBottom: '17px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '12px !important',
      paddingRight: '12px !important',
      paddingTop: '8px !important',
      paddingBottom: '10px !important',
    },
  },
  save: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '27px !important',
      paddingRight: '31px !important',
      paddingTop: '12px !important',
      paddingBottom: '17px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '18px !important',
      paddingRight: '18px !important',
      paddingTop: '8px !important',
      paddingBottom: '10px !important',
    },
  },
}));
const UpdateUserTeam = () => {
  const { addUser, editUser, dispatch } = React.useContext(AuthContext);

  const location = useLocation();
  const [Loading, setIsLoading] = React.useState(false);
  const [getTeams] = useLazyQuery(GET_TEAMS, { fetchPolicy: 'no-cache' });
  const [teams, setTeams] = React.useState([]);
  React.useEffect(() => {
    setIsLoading(true);
    getTeams().then((e) => setTeams(e.data.getTeams))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const newUser = editUser.map((e) => e.id);
  const [checked, setChecked] = React.useState(editUser);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const dialogClass = dialogStyle();
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const discardExit = async () => {
    await dispatch({ type: 'EDIT_USER', payload: editUser });
    navigate('/edit-user', { state: { userId: location.state.userId, teamId: location.state.teamId, path: location.state.path } });
  };
  const handleDialogClose = async () => {
    setOpen(false);
  };

  const checkedValues = checked.map((e) => e.id);
  const editUsers = editUser.map((e) => e.id);

  const backOnPreviousPage = async () => {
    if (JSON.stringify(checkedValues) !== JSON.stringify(editUsers)) {
      setOpen(true);
    } else {
      await dispatch({ type: 'ADD_USER', payload: addUser });
      navigate('/edit-user', { state: { userId: location.state.userId, teamId: location.state.teamId, path: location.state.path } });
    }
  };

  const saveTeam = async () => {
    await dispatch({ type: 'EDIT_USER', payload: checked });
    navigate('/edit-user', { state: { userId: location.state.userId, teamId: location.state.teamId, path: location.state.path } });
  };
  return (
    <Grid>
      <LoadingOverlay open={Loading} />
      <Grid container sx={{ backgroundColor: '#31132C', height: '51px', position: 'fixed' }} item xl={12} lg={12} xs={15} md={12} sm={15}>
        <Grid xl={5.3} lg={5.1} xs={4.4} md={5.3} sm={5.1}>
          <CustomIconButton
            sx={{ marginLeft: 2, marginTop: 0.5, marginBottom: 2 }}
            onClick={backOnPreviousPage}
          >
            <ArrowBackIosIcon sx={{ width: '25px', height: '25px' }} />
          </CustomIconButton>
        </Grid>

        <Grid item xl={6} lg={6} xs={5.5} md={5.6} sm={5.7}>
          <CustomTypography sx={{ marginTop: 1.7, marginBottom: 2, marginLeft: '10px' }}>
            Join Team
          </CustomTypography>
        </Grid>

        <Grid item xl={0.2} lg={0.5} xs={0.5} md={0.5} sm={0.9}>
          <CustomIconButton sx={{ marginTop: 1.2, fontSize: '15px', marginBottom: 2 }} onClick={saveTeam}>Save</CustomIconButton>
        </Grid>

        <Grid width="100%" style={{ height: 'calc(100vh - 51px)', overflowX: 'hidden', marginLeft: '10px' }} className="custom-Grid">
          <List
            sx={{
              width: '99%',
              overflow: 'auto',
            }}
          >
            {teams
              .filter((u) => !newUser.includes(u.id))
              .map((row) => (

                <>
                  <ListItemButton disableRipple style={{ backgroundColor: 'transparent' }} onClick={handleToggle(row)}>
                    <CustomListItemText primary={`${row.name}`} />
                    <StyledButton
                      checkedIcon={<CheckIcon />}
                      edge="start"
                      checked={checked.indexOf(row) !== -1}
                      tabIndex={-1}
                      inputProps={{ row }}
                    />
                  </ListItemButton>
                  <Divider />
                </>
              ))}
          </List>

        </Grid>
        <Grid>
          <Dialog
            PaperProps={{
              style: {
                borderRadius: '11px',
              },
            }}
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Grid style={{ backgroundColor: '#F7F7F8' }}>
              <DialogContent className={dialogClass.root}>
                <CustomDialogContentText>Are you sure you want to</CustomDialogContentText>
                <CustomDialogContentText>exit without saving?</CustomDialogContentText>
              </DialogContent>
            </Grid>
            <Divider />
            <Grid container>
              <CustomButton
                className={dialogClass.discard}
                onClick={discardExit}
              >
                Discard & Exit

              </CustomButton>
              <Divider orientation="vertical" flexItem />
              <CustomButton
                className={dialogClass.save}
                onClick={saveTeam}
              >
                Save & Exit

              </CustomButton>
            </Grid>
          </Dialog>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpdateUserTeam;
