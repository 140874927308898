import React from 'react';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import Error404Art from '../../assets/svgs/error404art.svg';
import { AUTH_TOKEN } from '../../constants/common';

const StyledImage = styled.img`
  max-width: 500px;
  width: 100%;
`;
const CustomTypo = styled(Typography)`
  &.MuiTypography-root {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: rgba(0, 0, 0, 0.56);
    max-width: 491px;
  }
`;
const Error404 = () => (
  <div className="error-page">
    <Grid container>
      <Grid
        item
        style={{ marging: '0 auto' }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        align="center"
      >
        <StyledImage src={Error404Art} alt="noart" draggable="false" />
      </Grid>
      <Grid
        item
        style={{ marging: '0 auto' }}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        align="center"
      >
        <CustomTypo style={{ marging: '0 auto' }} component="span">
          This page doesn’t exist or was removed!
          {localStorage.getItem(AUTH_TOKEN) ? (
            <CustomTypo sx={{ mx: 'auto' }}> We suggest you to try different page.</CustomTypo>
          ) : (
            <CustomTypo sx={{ mx: 'auto' }}>We suggest you to login.</CustomTypo>
          )}
        </CustomTypo>
      </Grid>
    </Grid>
  </div>
);

export default Error404;
