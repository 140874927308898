/* eslint-disable max-len */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputLabel from '@mui/material/InputLabel';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Dialog, DialogContent, DialogContentText, Divider, List, ListItemButton, ListItemText } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ClearIcon from '@mui/icons-material/Clear';
import { useMutation, useQuery } from '@apollo/client';
import AuthContext from '../../../context/AuthContext';
import ADD_MEMBERS from '../CreateTeam/addMemberMutation';
import GET_TEAMS_BY_ID from './getTeamsById';
import Toaster from '../../../components/Toaster/Toaster';
import EDIT_TEAM from './editTeamGraphMutation';
import LoadingOverlay from '../../../components/LoadingOverlay';

const CustomTypography = styled(Typography)`
&.MuiTypography-root{
    font-family: Lato;
    font-weight: 500;
    font-size: 17px;
    color: #FFFFFF;
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    color: #006CE3;
    text-transform: none;
  }
`;

const CustomTextField = styled(TextField)`
&.MuiTextField-root {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  color: #1A191A;
  background-color: #FFFFFF;
  text-transform: none;
  max-width: calc(100% - 100px);
  width: 100%;
}
`;

const CustomDialogContentText = styled(DialogContentText)`
&.MuiDialogContentText-root{
    font-family: Lato;
    font-weight: 500;
    font-size: 17px;
    color: #171617;
    text-align: center;
  }
`;
const CustomButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    color: #1D1C1D;
    text-transform: none;
    &:hover,
    &:focus {
    color: #1D1C1D;
    font-weight: 700;
    }
    &:active {
    color: #1D1C1D;
    font-weight: 700;
    }
  }
`;

const CustomListItemText = styled(Typography)`
&.MuiTypography-root{
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
  }
`;
const CustomInputLabel = styled(InputLabel)`
&.MuiInputLabel-root{
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    color: #A8AEB8;
  }
`;

const useStyles = makeStyles({
  input: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
});

const dialogStyle = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '45px !important',
      paddingRight: '45px !important',
      paddingTop: '23px !important',
      paddingBottom: '27px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
      paddingTop: '18px !important',
      paddingBottom: '20px !important',
    },
  },
  discard: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '19px !important',
      paddingRight: '19px !important',
      paddingTop: '12px !important',
      paddingBottom: '17px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '12px !important',
      paddingRight: '12px !important',
      paddingTop: '8px !important',
      paddingBottom: '10px !important',
    },
  },
  save: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '27px !important',
      paddingRight: '31px !important',
      paddingTop: '12px !important',
      paddingBottom: '17px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '18px !important',
      paddingRight: '18px !important',
      paddingTop: '8px !important',
      paddingBottom: '10px !important',
    },
  },
}));

const CustomIconButton = styled(IconButton)`
&.MuiIconButton-root{
    color: #FFFFFF;
    font-family: Lato;
    font-weight: 400;
  }
`;

const EditTeam = () => {
  const { dispatch, editTeam, addTeamMember } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const ref = React.useRef();
  const [addMembers] = useMutation(ADD_MEMBERS);
  const [open, setOpen] = React.useState(false);
  const [updateTeam] = useMutation(EDIT_TEAM);
  const [toastMsg, setToastMsg] = React.useState('');
  const [Loading, setIsLoading] = React.useState(true);
  const [inputData, setInputData] = React.useState('');
  const dialogClass = dialogStyle();

  const { data, loading } = useQuery(GET_TEAMS_BY_ID, { fetchPolicy: 'no-cache',
    variables: {
      teamId: parseInt(location.state.teamId, 10),
    } });

  React.useEffect(() => {
    if (!addTeamMember) {
      setInputData(data?.getTeamById?.name);
    }
  }, [data, data?.getTeamById, data?.getTeamById?.name]);

  React.useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  React.useEffect(() => {
    setInputData(addTeamMember);
  }, [addTeamMember]);

  const classes = useStyles();
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (editTeam?.length < 1) {
      setItems(data?.getTeamById?.teamMembers);
    }
  }, [data, data?.getTeamById, data?.getTeamById?.teamMembers]);

  React.useEffect(() => {
    setItems(editTeam);
  }, [editTeam]);

  const [toast, setToast] = React.useState({
    open: '',
    message: '',
  });

  const joinMemberToTheTeam = async () => {
    navigate('/update-member', { state: { teamId: location.state.teamId, userId: location.state.userId, path: location.state.path } });
    await dispatch({ type: 'ADD_TEAM_MEMBER', payload: ref.current.values.name });
    await dispatch({ type: 'EDIT_TEAM', payload: items });
  };

  const teamDetails = items.map((e) => e.id);
  const teamUsers = data?.getTeamById?.teamMembers?.map((e) => e.id);

  const teamNavigate = async () => {
    if (JSON.stringify(teamDetails) !== JSON.stringify(teamUsers) || data?.getTeamById?.name !== ref.current.values.name) {
      setOpen(true);
    } else {
      await dispatch({ type: 'EDIT_TEAM', payload: [] });
      await dispatch({ type: 'ADD_TEAM_MEMBER', payload: '' });
      await dispatch({ type: 'TAB_VALUE', payload: 0 });
      if (location.state.path === 'editUser') {
        navigate('/edit-user', { state: { userId: location.state.userId, path: 'user' } });
      } else if (location.state.path === 'user') {
        navigate('/users');
      }
    }
  };

  const onteamMemberDelete = (index) => async () => {
    const newData = items?.filter((item) => item.id !== index.id);
    await dispatch({ type: 'EDIT_TEAM', payload: newData });
    setItems(newData);
  };

  const teamSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const initialValues = {
    name: inputData || '',
    teams: items,
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast((prevstate) => ({
      ...prevstate,
      open: false,
    }));
    if (toastMsg === 'SUCCESS') {
      if (location.state.path === 'editUser') {
        navigate('/edit-user', { state: { userId: location.state.userId, path: 'user' } });
      } else if (location.state.path === 'user') {
        navigate('/users');
      }
    }
  };
  const onSaveData = async () => {
    const { values } = ref.current;
    try {
      let variables = {
        updateTeamInput: {
          id: parseInt(location.state.teamId, 10),
          name: values.name,
        },
      };
      const { data: { updateTeam: { id } } } = await updateTeam({
        variables,
      });
      if (id) {
        variables = {
          input: {
            user_ids: items.map((user) => parseInt(user.id, 10)) || [],
            team_id: parseInt(id, 10),
          },
        };
        addMembers({
          variables,
        });
      }
      setToast((prevstate) => ({
        ...prevstate,
        message: 'Team updated successfully',
        open: true,
        type: 'success',
      }), setToastMsg('SUCCESS'));
      await dispatch({ type: 'TAB_VALUE', payload: 0 });
    } catch (error) {
      const tokenExpire = error.networkError.result.errors.map((e) => e.message.includes('Context creation failed'));
      const teamExist = error.networkError.result.errors.map((e) => e.message).toString().slice(0, 34);
      const result = (teamExist) === ('Team with given name already exist');
      setToast((prevstate) => ({
        ...prevstate,
        message:
        result === true ? 'Team is already exists' : tokenExpire[0] === true ? 'Session Expired' : 'Something went wrong',
        open: true,
        type: 'error',
      }));
    }
  };

  const discardExit = async () => {
    await dispatch({ type: 'EDIT_TEAM', payload: [] });
    if (location.state.path === 'editUser') {
      navigate('/edit-user', { state: { userId: location.state.userId, path: 'user' } });
    } else if (location.state.path === 'user') {
      navigate('/users');
    }
  };

  const handleDialogClose = async () => {
    setOpen(false);
  };
  const onClickTeamMember = (value) => () => {
    navigate('/edit-user', { state: { userId: value.id, path: 'editTeam', teamId: location.state.teamId } });
  };
  return (
    <>
      <LoadingOverlay open={Loading} />
      <Grid container>
        <Grid container sx={{ backgroundColor: '#31132C', height: '51px', position: 'fixed' }} item xl={12} lg={12} xs={15} md={12} sm={15}>
          <Grid xl={5.3} lg={5.1} xs={4.4} md={5.3} sm={5.1}>
            <CustomIconButton sx={{ marginLeft: 2, marginTop: 0.5, marginBottom: 2 }} onClick={teamNavigate}>
              <ArrowBackIosIcon sx={{ width: '25px', height: '25px' }} />
            </CustomIconButton>
          </Grid>

          <Grid item xl={6} lg={6} xs={5.5} md={5.6} sm={5.7}>
            <CustomTypography sx={{ marginTop: 1.5, marginBottom: 2 }}>
              Team Details
            </CustomTypography>
          </Grid>

          <Grid item xl={0.2} lg={0.5} xs={0.5} md={0.5} sm={0.9}>
            <CustomIconButton sx={{ marginTop: 1.2, fontSize: '15px', marginBottom: 2 }} onClick={onSaveData}>Save</CustomIconButton>
          </Grid>

          <Grid width="100%" style={{ height: 'calc(100vh - 51px)', overflowX: 'hidden' }} className="custom-Grid">
            <Formik
              innerRef={ref}
              enableReinitialize
              initialValues={initialValues}
              validationSchema={teamSchema}
            >
              {({ handleChange, handleBlur, touched, errors, values }) => (
                <Grid container direction="column">
                  <Grid container direction="column" style={{ marginLeft: '20px', marginTop: '20px' }}>
                    <Grid container direction="row" style={{ borderBottom: '1px solid #E4E6E9' }}>
                      <Grid container className={classes.input}>
                        <CustomInputLabel sx={{ marginRight: '15px', marginTop: '6.5px', marginBottom: '22px' }}>Name</CustomInputLabel>
                        <CustomTextField
                          style={{ width: '200px' }}
                          type="text"
                          name="name"
                          id="name"
                          value={values.name || ''}
                          error={errors.name && touched.name}
                          helperText={touched.name && errors.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>

                    <Grid direction="row" sx={{ marginTop: '20px', marginBottom: '5px' }}>
                      <CustomInputLabel sx={{ marginRight: '30px' }}>Members</CustomInputLabel>
                    </Grid>

                    <Grid width="98%">
                      {items && (items
                    && items.map((teamMember) => (
                      <List sx={{ marginRight: '30px' }}>
                        <ListItemButton disableRipple style={{ backgroundColor: 'rgb(97, 97, 97, 0.1)' }}>
                          <ListItemText onClick={onClickTeamMember(teamMember)}>
                            <CustomListItemText>{`${teamMember.first_name} ${teamMember.last_name}`}</CustomListItemText>
                          </ListItemText>
                          <Button onClick={onteamMemberDelete(teamMember)}>
                            <ClearIcon style={{ color: '#171617' }} />
                          </Button>
                        </ListItemButton>
                      </List>
                    )))}

                    </Grid>

                    <Grid direction="row">
                      <StyledButton sx={{ marginLeft: '-10px', marginBottom: '35px' }} onClick={joinMemberToTheTeam}>+ Add Members</StyledButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Formik>
            <Grid item>
              <Toaster onClose={handleClose} {...toast} />
            </Grid>
          </Grid>

          <Grid>
            <Dialog
              PaperProps={{
                style: {
                  borderRadius: '11px',
                },
              }}
              open={open}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Grid style={{ backgroundColor: '#F7F7F8' }}>

                <DialogContent className={dialogClass.root}>
                  <CustomDialogContentText>Are you sure you want to</CustomDialogContentText>
                  <CustomDialogContentText>exit without saving?</CustomDialogContentText>
                </DialogContent>
              </Grid>
              <Divider />
              <Grid container>
                <CustomButton
                  className={dialogClass.discard}
                  onClick={discardExit}
                >
                  Discard & Exit

                </CustomButton>
                <Divider orientation="vertical" flexItem />
                <CustomButton
                  className={dialogClass.save}
                  onClick={onSaveData}
                >
                  Save & Exit

                </CustomButton>
              </Grid>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};
export default EditTeam;
