import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { AUTH_TOKEN, EXPIRES_IN, REFRESH_TOKEN, USER_ID } from '../../constants/common';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  maxWidth: 'calc(100% - 100px)',
  height: 120,
  bgcolor: 'background.paper',
  boxShadow: 24,
  outline: 'none !important',
};

const CustomTypography = styled(Typography)`
 &.MuiTypography-root{
     font-family: Lato;
     font-weight: 500;
     font-size: 16px;
   }
 `;

const CustomButton = styled(Button)`
   &.MuiButton-root {
     font-family: Lato;
     font-size: 16px;
     text-transform: none;
   }
 `;

const BasicModal = () => {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/');
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(EXPIRES_IN);
    setOpen(false);
  };
  return (
    <Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid>
          <Box sx={style}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid>
                <CustomTypography sx={{ marginTop: '9px' }}>
                  Session Expired
                </CustomTypography>
              </Grid>
              <Grid>
                <CustomTypography sx={{ marginTop: '10px' }}>
                  Please Login Again
                </CustomTypography>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <CustomButton sx={{ marginTop: '15px' }} onClick={handleClose}>Close</CustomButton>
            </Grid>
          </Box>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default BasicModal;
