/* eslint-disable max-len */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import TabPanel from '../../components/Tabs';
import UserList from '../../components/Tabs/User';
import TeamList from '../../components/Tabs/Team';
import Logout from '../../assets/svgs/logout.svg';
import { AUTH_TOKEN, USER_ID, REFRESH_TOKEN, EXPIRES_IN } from '../../constants/common';
import LOGOUT_USER from './logoutGraphQuery';
import AuthContext from '../../context/AuthContext';

const CustomTab = styled(Tab)`
  &.MuiTab-root {
    &.Mui-selected {
      color: #FFFFFF;
      font-weight: 900;
    }
    font-family: Lato;
    font-size: 17px;
    font-style: normal;
    font-weight: normal;
    text-transform: none;
    background-color: none;
    color: rgb(255,255,255,0.45)
  }
`;
const CustomDialogContentText = styled(DialogContentText)`
&.MuiDialogContentText-root{
    font-family: Lato;
    font-size: 14px;
    text-align: center;
  }
`;
const CustomButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    text-transform: none;
    font-size: 14px;
  }
`;

const useStyles = makeStyles({
  input: {
    '& div.MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'center',
      },
    },
  },

});

const tabProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const Users = () => {
  const { tabValue, dispatch } = React.useContext(AuthContext);
  const [value, setValue] = React.useState(tabValue);
  const navigate = useNavigate();
  const [openDialogBox, setOpenDialogBox] = React.useState(false);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  const classes = useStyles();
  const [userData] = useLazyQuery(LOGOUT_USER, {
    variables: {
      refreshToken,
    },
  });

  const onTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const onUserAdd = async () => {
    if (value === 1) {
      await dispatch({ type: 'EDIT_USER', payload: [] });
      await dispatch({ type: 'TAB_VALUE', payload: 1 });
      await dispatch({ type: 'ADD_USER', payload: {} });
      navigate('/add-user');
    } else if (value === 0) {
      await dispatch({ type: 'ADD_TEAM_MEMBER', payload: '' });
      await dispatch({ type: 'EDIT_TEAM', payload: [] });
      await dispatch({ type: 'ADD_MEMBER', payload: [] });
      await dispatch({ type: 'TAB_VALUE', payload: 0 });
      navigate('/new-team');
    }
  };

  const openDialog = () => {
    setOpenDialogBox(true);
  };
  const handleLogout = async () => {
    await userData();
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(EXPIRES_IN);
    navigate('/');
  };

  const handleClose = () => {
    setOpenDialogBox(false);
  };

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid container sx={{ backgroundColor: '#31132C', height: '51px', position: 'fixed' }} item xl={12} lg={12} xs={12} md={12} sm={12}>
        <Grid xl={2} lg={2} xs={2} md={2} sm={2}>
          <IconButton sx={{ marginTop: 0.4, marginBottom: 2 }} onClick={onUserAdd}>
            <AddIcon style={{ height: '30px', width: '30px', color: '#FFFFFF' }} />
          </IconButton>
        </Grid>

        <Grid
          item
          xl={8}
          lg={8}
          xs={8}
          md={8}
          sm={8}
        >
          <Tabs className={classes.input} indicatorColor value={value} onChange={onTabChange} aria-label="basic tabs example">

            <CustomTab label="Teams" id="team" {...tabProps(0)} />
            <CustomTab sx={{ marginLeft: -3 }} label="Users" id="user" {...tabProps(1)} />

          </Tabs>
        </Grid>

        <Grid item xl={2} lg={2} xs={2} md={2} sm={2}>
          <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton sx={{ marginTop: 1.05, marginBottom: 2 }} onClick={openDialog}>
              <img src={Logout} alt="logout" />
            </IconButton>
          </Grid>
        </Grid>

        <Grid width="100%" style={{ height: 'calc(100vh - 51px)', overflowY: 'auto' }} className="custom-Grid">
          <TabPanel value={value} index={0}>
            <TeamList />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <UserList />
          </TabPanel>
        </Grid>

        <Dialog
          open={openDialogBox}
          onClose={handleClose}
        >
          <DialogContent>
            <CustomDialogContentText>
              Are you sure you want to log out?
            </CustomDialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleClose}>Cancel</CustomButton>
            <CustomButton onClick={handleLogout}>
              Log out
            </CustomButton>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};
export default Users;
