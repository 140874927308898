import { gql } from '@apollo/client';

const EDIT_TEAM = gql`
  mutation Mutation($updateTeamInput: UpdateTeamInput) {
    updateTeam(updateTeamInput: $updateTeamInput) {
      id
      name
    }
  }
    `;

export default EDIT_TEAM;
