import { USERS, ADD_USER, EDIT_USER, ASSIGN_TEAM, CREATE_TEAM, EDIT_TEAM, UPDATE_USER_TEAM, ADD_MEMBER, UPDATE_MEMBER } from '../../constants/routes';

import Login from '../../pages/Login';
import UserTeam from '../../pages/UserTeam';
import AddUser from '../../pages/UserTeam/CreateUser/addUser';
import EditTeam from '../../pages/UserTeam/EditTeam/editTeam';
import UpdateMembers from '../../pages/UserTeam/EditTeam/updateMembers';
import EditUser from '../../pages/UserTeam/EditUser/editUser';
import AssignTeam from '../../pages/UserTeam/CreateUser/assignTeam';
import AddMembers from '../../pages/UserTeam/CreateTeam/addMember';
import CreateTeam from '../../pages/UserTeam/CreateTeam/newTeam';
import UpdateUserTeam from '../../pages/UserTeam/EditUser/updateUserTeam';

const routes = [
  {
    protected: false,
    path: '/',
    name: 'Login',
    element: <Login />,
  },
  {
    protected: true,
    path: `/${USERS}`,
    name: 'Users',
    element: <UserTeam />,
  },
  {
    protected: true,
    path: `/${ADD_USER}`,
    name: 'AddUser',
    element: <AddUser />,
  },
  {
    protected: true,
    path: `/${EDIT_USER}`,
    name: 'EditUser',
    element: <EditUser />,
  },
  {
    protected: true,
    path: `/${ASSIGN_TEAM}`,
    name: 'AssignTeam',
    element: <AssignTeam />,
  },
  {
    protected: true,
    path: `/${CREATE_TEAM}`,
    name: 'CreateTeam',
    element: <CreateTeam />,
  },
  {
    protected: true,
    path: `/${EDIT_TEAM}`,
    name: 'EditTeam',
    element: <EditTeam />,
  },
  {
    protected: true,
    path: `/${UPDATE_USER_TEAM}`,
    name: 'UpdateUserTeam',
    element: <UpdateUserTeam />,
  },
  {
    protected: true,
    path: `/${ADD_MEMBER}`,
    name: 'AddMembers',
    element: <AddMembers />,
  },
  {
    protected: true,
    path: `/${UPDATE_MEMBER}`,
    name: 'UpdateMembers',
    element: <UpdateMembers />,
  },
];

export default routes;
