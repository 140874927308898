/* eslint-disable max-len */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Dialog, DialogContent, DialogContentText, Divider, List, ListItem, ListItemText } from '@mui/material';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import ClearIcon from '@mui/icons-material/Clear';
import { useMutation } from '@apollo/client';
import NumberFormat from 'react-number-format';
import AuthContext from '../../../context/AuthContext';
import ADD_USER from './addUserMutation';
import Toaster from '../../../components/Toaster/Toaster';
import LoadingOverlay from '../../../components/LoadingOverlay';

const CustomTypography = styled(Typography)`
&.MuiTypography-root{
    font-family: Lato;
    font-weight: 500;
    font-size: 17px;
    color: #FFFFFF;
  }
`;

const CustomButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    color: #007AFF;
    text-transform: none;
    border-radius: 95px; 
    width: 318px;
    height: 56px;
    background-color: rgb(0, 122, 255, 0.1)
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    color: #006CE3;
    text-transform: none;
    margin-left: 1px;
    margin-bottom: 35px;
  }
`;

const CustomTextField = styled(TextField)`
&.MuiTextField-root {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  color: #1A191A;
  background-color: #FFFFFF;
  text-transform: none;
  max-width: calc(100% - 100px);
  width: 100%;
}
`;

const CustomPhoneNumber = styled(TextField)`
&.MuiTextField-root {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  color: #1A191A;
  background-color: #FFFFFF;
  text-transform: none;
}
`;

const CustomListItemText = styled(Typography)`
&.MuiTypography-root{
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
  }
`;
const CustomInputLabel = styled(InputLabel)`
&.MuiInputLabel-root{
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    color: #A8AEB8;
  }
`;

const CustomDialogContentText = styled(DialogContentText)`
&.MuiDialogContentText-root{
    font-family: Lato;
    font-weight: 500;
    font-size: 17px;
    color: #171617;
    text-align: center;
  }
`;
const CustomDialogButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    color: #1D1C1D;
    text-transform: none;
    &:hover,
    &:focus {
    color: #1D1C1D;
    font-weight: 700;
    }
    &:active {
    color: #1D1C1D;
    font-weight: 700;
    }
  }
`;

const CustomGrid = styled(Grid)`
&.MuiGrid-root{
  border-bottom: 1px solid #E4E6E9;
  margin-left: 10px
}`;

const selectStyle = makeStyles({
  select: {
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    border: '0 !important',
    outline: 'none !important',
    boxShadow: 'none !important',
    marginLeft: '10px',
    marginBottom: '10px',
    fontFamily: 'Lato',
    fontSize: '16px',
    marginTop: '-3.5px',
    minWidth: '50px',
    cursor: 'pointer',

    background: 'url(https://cdn1.iconfinder.com/data/icons/universal-icons-set-for-web-and-mobile/100/controls_0-12-512.png) no-repeat right center',
    backgroundSize: '27px 27px',

    'select::-ms-expand': {
      display: 'none',
    },
  },
});
const useStyles = makeStyles({
  input: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
  select: {
    border: 'none',
    outline: 'none',
    marginLeft: '12px',
    marginBottom: '10px',
    fontFamily: 'Lato',
    fontSize: '16px',
    marginTop: '-3.5px',
  },
});

const CustomIconButton = styled(IconButton)`
&.MuiIconButton-root{
    font-family: Lato;
    font-weight: 400;
    color: #FFFFFF;
  }
`;
const dialogStyle = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '45px !important',
      paddingRight: '45px !important',
      paddingTop: '23px !important',
      paddingBottom: '27px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
      paddingTop: '18px !important',
      paddingBottom: '20px !important',
    },
  },
  discard: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '19px !important',
      paddingRight: '19px !important',
      paddingTop: '12px !important',
      paddingBottom: '17px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '12px !important',
      paddingRight: '12px !important',
      paddingTop: '8px !important',
      paddingBottom: '10px !important',
    },
  },
  save: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '27px !important',
      paddingRight: '31px !important',
      paddingTop: '12px !important',
      paddingBottom: '17px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '18px !important',
      paddingRight: '18px !important',
      paddingTop: '8px !important',
      paddingBottom: '10px !important',
    },
  },
}));

const Adduser = () => {
  const { addUser, addTeam, dispatch } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [countryCode] = React.useState();
  const [countryValue, setCountryValue] = React.useState('1');
  const [addUsers] = useMutation(ADD_USER);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [Loading, setIsLoading] = React.useState(false);
  const [toastMsg, setToastMsg] = React.useState('');
  const dialogClass = dialogStyle();
  const selectClass = selectStyle();
  const [items, setItems] = React.useState(addTeam);
  const [toast, setToast] = React.useState({
    open: '',
    message: '',
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast((prevstate) => ({
      ...prevstate,
      open: false,
    }));

    if (toastMsg === 'SUCCESS') {
      navigate('/users');
    }
  };

  const ref = React.useRef(null);
  const joinUserToTheTeam = async () => {
    navigate('/assign-team');
    await dispatch({ type: 'ADD_USER', payload: ref.current.values });
    await dispatch({ type: 'ADD_TEAM', payload: addTeam });
  };

  const onTeamDelete = (index) => async () => {
    const newData = items.filter((item) => item.id !== index.id);
    await dispatch({ type: 'ADD_TEAM', payload: newData });
    setItems(newData);
  };

  const userSchema = Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required').min(8).matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/, 'Password must contains A-Z, a-z, 0-9, and special characters @$!%*?&'),
    call: Yup.string().notRequired(),
  });

  const discardExit = async () => {
    await dispatch({ type: 'ADD_TEAM', payload: [] });
    await dispatch({ type: 'ADD_USER', payload: {} });
    await dispatch({ type: 'TAB_VALUE', payload: 1 });
    navigate('/users');
  };

  const handleDialogClose = async () => {
    setOpen(false);
  };
  const initialValues = {
    firstname: '' || addUser.firstname,
    lastname: '' || addUser.lastname,
    email: '' || addUser.email,
    password: '' || addUser.password,
    call: addUser.call || '',
    check: addUser.check === false ? addUser.check : true,
    countrycode: countryCode,
  };
  const userNavigate = async () => {
    if (items.length > 0 || JSON.stringify(initialValues) !== JSON.stringify(ref.current.values)) {
      setOpen(true);
    } else {
      await dispatch({ type: 'ADD_USER', payload: {} });
      await dispatch({ type: 'ADD_TEAM', payload: [] });
      await dispatch({ type: 'TAB_VALUE', payload: 1 });
      navigate('/users');
    }
  };

  return (
    <>
      <LoadingOverlay open={Loading} />
      <Grid container>
        <Grid container sx={{ backgroundColor: '#31132C', height: '51px' }}>
          <Grid item xl={5.8} lg={5.5} xs={5} md={5.5} sm={5.5}>
            <CustomIconButton
              sx={{ marginLeft: 2, marginTop: 0.5, marginBottom: 2 }}
              onClick={userNavigate}
            >
              <ArrowBackIosIcon sx={{ width: '25px', height: '25px' }} />
            </CustomIconButton>
          </Grid>

          <Grid item xl={6} lg={6} xs={6} md={5.7} sm={6}>
            <CustomTypography sx={{ marginTop: 1.7, marginBottom: 2 }}>
              New User
            </CustomTypography>
          </Grid>

        </Grid>
        <Grid width="100%" style={{ height: 'calc(100vh - 51px)', overflowX: 'hidden' }}>
          <Formik
            innerRef={ref}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={userSchema}
            onSubmit={async (values) => {
              try {
                const phoneNumber = values.call.replace(/[^\d]/g, '');
                const variables = {
                  user: {
                    first_name: values.firstname,
                    last_name: values.lastname,
                    email: values.email,
                    password: values.password,
                    phone_number: phoneNumber || null,
                    phone_country_code: parseInt(countryValue, 10),
                    is_active: values.check || false,
                    team_ids: items.map((team) => parseInt(team.id, 10)) || [],
                  },
                };
                setIsLoading(true);
                const { loading } = await addUsers({
                  variables,
                });
                if (!loading) {
                  setIsLoading(false);
                }
                setToast((prevstate) => ({
                  ...prevstate,
                  message: 'User added successfully!',
                  open: true,
                  type: 'success',
                }), setToastMsg('SUCCESS'));
                await dispatch({ type: 'TAB_VALUE', payload: 1 });
              } catch (error) {
                setIsLoading(false);
                const errorMessage = error.networkError.result.errors.map((e) => e.message).toString().slice(0, 24);
                const result = (errorMessage) === ('User with given username');

                setToast((prevstate) => ({
                  ...prevstate,
                  message: result === true
                    ? 'Email is already exists'
                    : 'Something went wrong!',
                  open: true,
                  type: 'error',
                }));
              }
            }}
          >
            {({ handleSubmit, handleChange, handleBlur, touched, errors, values, setFieldValue }) => (
              <Grid container direction="column" style={{ marginTop: '20px' }}>
                <Grid container direction="column">
                  <CustomGrid container direction="row">
                    <Grid container className={classes.input}>
                      <CustomInputLabel sx={{ marginRight: '15px', marginTop: '6.5px', marginBottom: '22px' }}>First Name</CustomInputLabel>
                      <CustomTextField
                        type="text"
                        name="firstname"
                        id="firstname"
                        value={values.firstname || ''}
                        error={errors.firstname && touched.firstname}
                        helperText={touched.firstname && errors.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </CustomGrid>
                  <CustomGrid container direction="row">
                    <Grid container className={classes.input} sx={{ marginTop: '10px' }}>
                      <CustomInputLabel sx={{ marginRight: '18px', marginTop: '6.5px', marginBottom: '22px' }}>Last Name</CustomInputLabel>
                      <CustomTextField
                        type="text"
                        name="lastname"
                        id="lastname"
                        value={values.lastname || ''}
                        error={errors.lastname && touched.lastname}
                        helperText={touched.lastname && errors.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </CustomGrid>
                  <CustomGrid container direction="row">
                    <Grid container className={classes.input} sx={{ marginTop: '10px' }}>
                      <CustomInputLabel sx={{ marginRight: '49px', marginTop: '6.5px', marginBottom: '22px' }}>Email</CustomInputLabel>
                      <CustomTextField
                        type="text"
                        name="email"
                        id="email"
                        value={values.email || ''}
                        error={errors.email && touched.email}
                        helperText={touched.email && errors.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </CustomGrid>
                  <CustomGrid container direction="row">
                    <Grid container className={classes.input} sx={{ marginTop: '10px' }}>
                      <CustomInputLabel sx={{ marginRight: '40px', marginTop: '6.2px', marginBottom: '18px' }}>Cell</CustomInputLabel>
                      <Field as="select" name="countrycode" className={selectClass.select} defaultValue={values.countrycode} onChange={(e) => setCountryValue(e.target.value)}>
                        <option value="1">+1</option>
                        <option value="91">+91</option>
                      </Field>

                      <NumberFormat
                        name="call"
                        id="call"
                        value={values.call || ''}
                        error={errors.call && touched.call}
                        helperText={touched.call && errors.call}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        customInput={CustomPhoneNumber}
                        format="### ### ####"
                      />
                    </Grid>
                  </CustomGrid>
                  <CustomGrid container direction="row">
                    <Grid container className={classes.input} sx={{ marginTop: '10px' }}>
                      <CustomInputLabel sx={{ marginRight: '22px', marginTop: '5px', marginBottom: '22px' }}>Password</CustomInputLabel>
                      <CustomTextField
                        type="password"
                        name="password"
                        id="password"
                        value={values.password || ''}
                        error={errors.password && touched.password}
                        helperText={touched.password && errors.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </CustomGrid>
                  <CustomGrid container direction="row">
                    <Grid container>
                      <CustomInputLabel sx={{ marginRight: '30px', marginTop: '20px', marginBottom: '22px' }}>Active</CustomInputLabel>
                      <Checkbox value={values.check} onChange={() => setFieldValue('check', !values.check)} checked={values.check} />
                    </Grid>
                  </CustomGrid>
                  <Grid direction="row" sx={{ marginTop: '20px', marginBottom: '5px', marginLeft: '10px' }}>
                    <CustomInputLabel sx={{ marginRight: '30px' }}>Teams</CustomInputLabel>
                  </Grid>
                  <Grid width="98%">
                    {items !== 'null' && (items
                    && items.map((team) => (
                      <List>
                        <ListItem style={{ backgroundColor: 'rgb(97, 97, 97, 0.1)' }}>
                          <ListItemText>
                            <CustomListItemText>{team.name}</CustomListItemText>
                          </ListItemText>
                          <Button onClick={onTeamDelete(team)}>
                            <ClearIcon style={{ color: '#171617' }} />
                          </Button>
                        </ListItem>
                      </List>
                    )))}

                  </Grid>
                  <Grid direction="row">
                    <StyledButton onClick={joinUserToTheTeam}>+ Join Team</StyledButton>
                  </Grid>
                  <Grid
                    sx={{ marginBottom: '20px' }}
                    container
                    justifyContent="center"
                  >
                    <CustomButton onClick={() => handleSubmit()}>Create User</CustomButton>
                  </Grid>

                </Grid>
                <Grid>
                  <Dialog
                    PaperProps={{
                      style: {
                        borderRadius: '11px',
                      },
                    }}
                    open={open}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <Grid style={{ backgroundColor: '#F7F7F8' }}>
                      <DialogContent className={dialogClass.root}>
                        <CustomDialogContentText>Are you sure you want to</CustomDialogContentText>
                        <CustomDialogContentText>exit without saving?</CustomDialogContentText>
                      </DialogContent>
                    </Grid>
                    <Divider />
                    <Grid container>
                      <CustomDialogButton
                        className={dialogClass.discard}
                        onClick={discardExit}
                      >
                        Discard & Exit

                      </CustomDialogButton>
                      <Divider orientation="vertical" flexItem />
                      <CustomDialogButton
                        className={dialogClass.save}
                        onClick={() => handleSubmit()}
                      >
                        Save & Exit

                      </CustomDialogButton>
                    </Grid>
                  </Dialog>
                </Grid>
              </Grid>
            )}
          </Formik>
          <Grid item>
            <Toaster onClose={handleClose} {...toast} />
          </Grid>
        </Grid>

      </Grid>

    </>
  );
};
export default Adduser;
