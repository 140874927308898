import { gql } from '@apollo/client';

const ADMIN_LOGIN = gql`
query Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      idToken
      expiresIn
      tokenType
      userId
      refreshToken
      accessToken
    }
  }
`;

export default ADMIN_LOGIN;
