import { createGlobalStyle } from 'styled-components';

const MuiGlobalStyles = createGlobalStyle`
body {
    .MuiInputBase-root,
    .MuiTypography-root,
    .MuiFormLabel-root,
    .MuiInputLabel-root,
    .MuiMenuItem-root,
    .MuiTab-root,
    .Mui-selected,
    .MuiIconButton-root,
    .MuiCheckbox-root,
    .MuiGrid-root,
    .Mui-checked,
    .MuiButton-root,
    .MuiTextField-root,
    .MuiDialogActions-root,
    .MuiDialog-root,
    .MuiDialogContent-root,
    .MuiDialogContentText-root,
    .MuiListItem-root,
    .MuiListItemText-root,
    .MuiPaper-root{
        font-family:'Lato';
    }
}
`;

export default MuiGlobalStyles;
