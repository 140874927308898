/* eslint-disable max-len */
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputLabel from '@mui/material/InputLabel';
import { useLocation, useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { Dialog, DialogContent, DialogContentText, Divider, List, ListItemButton, ListItemText } from '@mui/material';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import ClearIcon from '@mui/icons-material/Clear';
import { useMutation, useQuery } from '@apollo/client';
import NumberFormat from 'react-number-format';
import AuthContext from '../../../context/AuthContext';
import GET_USER_BY_ID from './getUserGraphQuery';
import EDIT_USER from './editUserGraphMutation';
import Toaster from '../../../components/Toaster/Toaster';
import LoadingOverlay from '../../../components/LoadingOverlay';

const CustomTypography = styled(Typography)`
&.MuiTypography-root{
    font-family: Lato;
    font-weight: 500;
    font-size: 17px;
    color: #FFFFFF;
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    color: #006CE3;
    text-transform: none;
  }
`;

const CustomTextField = styled(TextField)`
&.MuiTextField-root {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  color: #1A191A;
  background-color: #FFFFFF;
  text-transform: none;
  max-width: calc(100% - 100px);
  width: 100%;
}
`;

const CustomListItemText = styled(Typography)`
&.MuiTypography-root{
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
  }
`;
const CustomInputLabel = styled(InputLabel)`
&.MuiInputLabel-root{
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    color: #A8AEB8;
  }
`;

const useStyles = makeStyles({
  input: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
  select: {
    border: 'none',
    outline: 'none',
    marginLeft: '12px',
    marginBottom: '10px',
    fontFamily: 'Lato',
    fontSize: '16px',
    marginTop: '-3.5px',
  },
});

const CustomIconButton = styled(IconButton)`
&.MuiIconButton-root{
    color: #FFFFFF;
    font-family: Lato;
    font-weight: 400;
  }
`;

const CustomDialogContentText = styled(DialogContentText)`
&.MuiDialogContentText-root{
    font-family: Lato;
    font-weight: 500;
    font-size: 17px;
    color: #171617;
    text-align: center;
  }
`;
const CustomButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    color: #1D1C1D;
    text-transform: none;
    &:hover,
    &:focus {
    color: #1D1C1D;
    font-weight: 700;
    }
    &:active {
    color: #1D1C1D;
    font-weight: 700;
    }
  }
`;
const CustomPhoneNumber = styled(TextField)`
&.MuiTextField-root {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  color: #1A191A;
  background-color: #FFFFFF;
  text-transform: none;
}
`;

const selectStyle = makeStyles({
  select: {
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    border: '0 !important',
    outline: 'none !important',
    boxShadow: 'none !important',
    marginLeft: '10px',
    marginBottom: '10px',
    fontFamily: 'Lato',
    fontSize: '16px',
    marginTop: '-3.5px',
    minWidth: '50px',
    cursor: 'pointer',

    background: 'url(https://cdn1.iconfinder.com/data/icons/universal-icons-set-for-web-and-mobile/100/controls_0-12-512.png) no-repeat right center',
    backgroundSize: '27px 27px',

    'select::-ms-expand': {
      display: 'none',
    },
  },
});

const dialogStyle = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '45px !important',
      paddingRight: '45px !important',
      paddingTop: '23px !important',
      paddingBottom: '27px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
      paddingTop: '18px !important',
      paddingBottom: '20px !important',
    },
  },
  discard: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '19px !important',
      paddingRight: '19px !important',
      paddingTop: '12px !important',
      paddingBottom: '17px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '12px !important',
      paddingRight: '12px !important',
      paddingTop: '8px !important',
      paddingBottom: '10px !important',
    },
  },
  save: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '27px !important',
      paddingRight: '31px !important',
      paddingTop: '12px !important',
      paddingBottom: '17px !important',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '18px !important',
      paddingRight: '18px !important',
      paddingTop: '8px !important',
      paddingBottom: '10px !important',
    },
  },
}));

const Edituser = () => {
  const { editUser, dispatch, addUser } = React.useContext(AuthContext);
  const [updateUser] = useMutation(EDIT_USER);
  const [toastMsg, setToastMsg] = React.useState('');
  const [Loading, setIsLoading] = React.useState(true);
  const [inputData, setInputData] = React.useState(addUser);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const selectClass = selectStyle();
  const dialogClass = dialogStyle();
  const ref = React.useRef();
  const [toast, setToast] = React.useState({
    open: '',
    message: '',
  });
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast((prevstate) => ({
      ...prevstate,
      open: false,
    }));
    if (toastMsg === 'SUCCESS') {
      if (location.state.path === 'editTeam') {
        navigate('/edit-team', { state: { teamId: location.state.teamId, path: 'user' } });
      } else if (location.state.path === 'user') {
        navigate('/users');
      }
    }
  };

  const { data, loading } = useQuery(GET_USER_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: location.state.userId,
      showTeam: true,
    },
  });

  React.useEffect(() => {
    setInputData(data?.user);
  }, [data, data?.user]);

  React.useEffect(() => {
    setInputData(addUser);
  }, [addUser]);

  const [items, setItems] = React.useState([]);
  React.useEffect(() => {
    if (editUser.length < 1) {
      setItems(data?.user?.teams);
    }
  }, [data, data?.user, data?.user?.teams]);

  React.useEffect(() => {
    setItems(editUser);
  }, [editUser]);

  React.useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  const joinUserToTheTeam = async () => {
    await dispatch({ type: 'ADD_USER', payload: ref.current.values });
    await dispatch({ type: 'EDIT_USER', payload: items });
    navigate('/update-user-team', { state: { teamId: location.state.teamId, userId: location.state.userId, path: location.state.path } });
  };
  const onTeamDelete = (index) => async () => {
    const newData = items.filter((item) => item.id !== index.id);
    await dispatch({ type: 'EDIT_USER', payload: newData });
    setItems(newData);
  };
  const onSaveData = async () => {
    const { values } = ref.current;
    const phoneNumber = values.phone_number.replace(/[^\d]/g, '');
    try {
      const variables = {
        user: {
          id: parseInt(location.state.userId, 10),
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone_number: phoneNumber || '',
          phone_country_code: parseInt(values.phone_country_code, 10),
          is_active: values.is_active,
          password: values.password,
          team_ids: items.map((team) => parseInt(team.id, 10)) || [],
        },
      };
      await updateUser({
        variables,
      });
      setToast((prevstate) => ({
        ...prevstate,
        message: 'User updated successfully',
        open: true,
        type: 'success',
      }), setToastMsg('SUCCESS'));
      await dispatch({ type: 'TAB_VALUE', payload: 1 });
      await dispatch({ type: 'EDIT_USER', payload: [] });
      await dispatch({ type: 'ADD_USER', payload: {} });
    } catch (error) {
      const errorMessage = error.networkError.result.errors.map((e) => e.message).toString().slice(0, 35);
      const result = (errorMessage) === ('Phone number must contain 10 digits');

      const passwordErrorMessage = error.networkError.result.errors.map((e) => e.message).toString().slice(0, 35);
      const passwordResult = (passwordErrorMessage) === ('Password does not conform to policy');

      const tokenExpire = error.networkError.result.errors.map((e) => e.message.includes('Context creation failed'));
      setToast((prevstate) => ({
        ...prevstate,
        message:
          result === true ? 'Please Enter valid phone number'
            : passwordResult === true ? 'Invalid Password'
              : tokenExpire[0] === true ? 'Session Expired'
                : 'Email already Exists',
        open: true,
        type: 'error',
      }));
    }
  };

  const discardExit = async () => {
    await dispatch({ type: 'EDIT_TEAM', payload: [] });
    if (location.state.path === 'editTeam') {
      navigate('/edit-team', { state: { teamId: location.state.teamId, path: 'user' } });
    } else if (location.state.path === 'user') {
      navigate('/users');
    }
  };
  const handleDialogClose = async () => {
    setOpen(false);
  };
  const userSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().min(8).matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/, 'Password must contains A-Z, a-z, 0-9, and special characters @$!%*?&'),
    phone_number: Yup.string(),
  });

  const initialValues = {
    first_name: inputData?.first_name || '',
    last_name: inputData?.last_name || '',
    email: inputData?.email || '',
    password: '',
    phone_number: inputData?.phone_number || '',
    is_active: inputData?.is_active || false,
    phone_country_code: inputData?.phone_country_code || '',
  };

  const userDetails = items.map((e) => e.id);
  const teamUsers = data?.user?.teams?.map((e) => e.id);
  const userNavigate = async () => {
    if (JSON.stringify(userDetails) !== JSON.stringify(teamUsers) || JSON.stringify(initialValues) !== JSON.stringify(ref.current.values)) {
      setOpen(true);
    } else {
      await dispatch({ type: 'EDIT_USER', payload: [] });
      await dispatch({ type: 'ADD_USER', payload: {} });
      await dispatch({ type: 'TAB_VALUE', payload: 1 });

      if (location.state.path === 'editTeam') {
        navigate('/edit-team', { state: { teamId: location.state.teamId, path: 'user' } });
      } else if (location.state.path === 'user') {
        navigate('/users');
      }
    }
  };
  const onTeamClick = (value) => () => {
    navigate('/edit-team', { state: { teamId: value.id, path: 'editUser', userId: location.state.userId } });
  };
  return (
    <>
      <LoadingOverlay open={Loading} />
      <Grid>
        <Grid container sx={{ backgroundColor: '#31132C', height: '51px', position: 'fixed' }} item xl={12} lg={12} xs={15} md={12} sm={15}>
          <Grid xl={5.3} lg={5.1} xs={4.4} md={5.3} sm={5.1}>
            <CustomIconButton sx={{ marginLeft: 2, marginTop: 0.5, marginBottom: 2 }} onClick={userNavigate}>
              <ArrowBackIosIcon sx={{ width: '25px', height: '25px' }} />
            </CustomIconButton>
          </Grid>

          <Grid item xl={6} lg={6} xs={5.5} md={5.6} sm={5.7}>
            <CustomTypography sx={{ marginTop: 1.7, marginBottom: 2 }}>
              User Details
            </CustomTypography>
          </Grid>

          <Grid item xl={0.2} lg={0.5} xs={0.5} md={0.5} sm={0.9}>
            <CustomIconButton sx={{ marginTop: 1.2, fontSize: '15px', marginBottom: 2 }} onClick={onSaveData}>Save</CustomIconButton>
          </Grid>

          <Grid width="100%" style={{ height: 'calc(100vh - 51px)', overflowX: 'hidden' }} className="custom-Grid">
            <Formik
              innerRef={ref}
              enableReinitialize
              initialValues={initialValues}
              validationSchema={userSchema}
            >
              {({ handleChange, handleBlur, touched, errors, values, setFieldValue }) => (
                <Grid container direction="column">
                  <Grid container direction="column" style={{ marginLeft: '20px', marginTop: '20px' }}>
                    <Grid container direction="row" style={{ borderBottom: '1px solid #E4E6E9' }}>
                      <Grid container className={classes.input}>
                        <CustomInputLabel sx={{ marginRight: '15px', marginTop: '6.5px', marginBottom: '22px' }}>First Name</CustomInputLabel>
                        <CustomTextField
                          type="text"
                          name="first_name"
                          id="first_name"
                          value={values.first_name || ''}
                          error={errors.first_name && touched.first_name}
                          helperText={touched.first_name && errors.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ borderBottom: '1px solid #E4E6E9' }}>
                      <Grid container className={classes.input} sx={{ marginTop: '10px' }}>
                        <CustomInputLabel sx={{ marginRight: '18px', marginTop: '6.5px', marginBottom: '22px' }}>Last Name</CustomInputLabel>
                        <CustomTextField
                          type="text"
                          name="last_name"
                          id="last_name"
                          value={values.last_name || ''}
                          error={errors.last_name && touched.last_name}
                          helperText={touched.last_name && errors.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ borderBottom: '1px solid #E4E6E9' }}>
                      <Grid container className={classes.input} sx={{ marginTop: '10px' }}>
                        <CustomInputLabel sx={{ marginRight: '49px', marginTop: '6.5px', marginBottom: '22px' }}>Email</CustomInputLabel>
                        <CustomTextField
                          type="text"
                          name="email"
                          id="email"
                          value={values.email || ''}
                          error={errors.email && touched.email}
                          helperText={touched.email && errors.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ borderBottom: '1px solid #E4E6E9' }}>
                      <Grid container className={classes.input} sx={{ marginTop: '10px' }}>
                        <CustomInputLabel sx={{ marginRight: '40px', marginTop: '6.2px', marginBottom: '18px' }}>Cell</CustomInputLabel>
                        <Field as="select" name="phone_country_code" className={selectClass.select} defaultValue={1}>
                          <option value="1">+1</option>
                          <option value="91">+91</option>
                        </Field>

                        <NumberFormat
                          name="phone_number"
                          id="phone_number"
                          value={values.phone_number || ''}
                          error={errors.phone_number && touched.phone_number}
                          helperText={touched.phone_number && errors.phone_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          customInput={CustomPhoneNumber}
                          format="### ### ####"
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ borderBottom: '1px solid #E4E6E9' }}>
                      <Grid container className={classes.input} sx={{ marginTop: '10px' }}>
                        <CustomInputLabel sx={{ marginRight: '22px', marginTop: '5px', marginBottom: '22px' }}>Password</CustomInputLabel>
                        <CustomTextField
                          type="password"
                          name="password"
                          id="password"
                          placeholder="********"
                          value={values.password || ''}
                          error={errors.password && touched.password}
                          helperText={touched.password && errors.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" style={{ borderBottom: '1px solid #E4E6E9' }}>
                      <Grid container>
                        <CustomInputLabel sx={{ marginRight: '30px', marginTop: '20px', marginBottom: '22px' }}>Active</CustomInputLabel>
                        <Checkbox value={values.is_active} onChange={() => setFieldValue('is_active', !values.is_active)} checked={values.is_active} />
                      </Grid>
                    </Grid>
                    <Grid direction="row" sx={{ marginTop: '20px', marginBottom: '5px' }}>
                      <CustomInputLabel sx={{ marginRight: '30px' }}>Teams</CustomInputLabel>
                    </Grid>

                    <Grid width="98%">
                      {items && (items
                    && items.map((team) => (
                      <List sx={{ marginRight: '30px' }}>
                        <ListItemButton disableRipple style={{ backgroundColor: 'rgb(97, 97, 97, 0.1)' }}>
                          <ListItemText onClick={onTeamClick(team)}>
                            <CustomListItemText>{team.name}</CustomListItemText>
                          </ListItemText>
                          <Button onClick={onTeamDelete(team)}>
                            <ClearIcon style={{ color: '#171617' }} />
                          </Button>
                        </ListItemButton>
                      </List>
                    )))}
                    </Grid>

                    <Grid direction="row">
                      <StyledButton sx={{ marginLeft: '-10px', marginBottom: '35px' }} onClick={joinUserToTheTeam}>+ Join Team</StyledButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Formik>
            <Grid item>
              <Toaster onClose={handleClose} {...toast} />
            </Grid>
          </Grid>
          <Grid>
            <Dialog
              PaperProps={{
                style: {
                  borderRadius: '11px',
                },
              }}
              open={open}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Grid style={{ backgroundColor: '#F7F7F8' }}>
                <DialogContent className={dialogClass.root}>
                  <CustomDialogContentText>Are you sure you want to</CustomDialogContentText>
                  <CustomDialogContentText>exit without saving?</CustomDialogContentText>
                </DialogContent>
              </Grid>
              <Divider />
              <Grid container>
                <CustomButton
                  className={dialogClass.discard}
                  onClick={discardExit}
                >
                  Discard & Exit

                </CustomButton>
                <Divider orientation="vertical" flexItem />
                <CustomButton
                  className={dialogClass.save}
                  onClick={onSaveData}
                >
                  Save & Exit

                </CustomButton>
              </Grid>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
};
export default Edituser;
