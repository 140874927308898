import { gql } from '@apollo/client';

const CREATE_TEAM = gql`
mutation CreateTeam($name: String!) {
    createTeam(name: $name) {
      id
      name
      created_by
      updated_by
      created_at
      updated_at
    }
  }
`;

export default CREATE_TEAM;
