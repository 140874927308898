import { gql } from '@apollo/client';

const GET_USER_BY_ID = gql`
query User($userId: String, $showTeam: Boolean) {
    user(userId: $userId, showTeam: $showTeam) {
      id
      first_name
      last_name
      email
      is_active
      phone_number
      phone_country_code
      teams {
        id
        name
      }
    }
  }
`;

export default GET_USER_BY_ID;
