import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMuiAlert = styled(MuiAlert)`
  &.MuiPaper-root {
    font-family: 'Lato', sans-serif;
    text-transform: none;
    color: white;
  }
`;
const Alert = React.forwardRef((props, ref) => (
  <StyledMuiAlert {...props} elevation={6} ref={ref} variant="filled" />
));
const propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};
const defaultProps = {
  type: 'success',
};
const Toaster = ({ open, message, type, onClose }) => (
  <Stack spacing={2}>
    <Snackbar open={open} autoHideDuration={1000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={onClose}>
      <Alert severity={type} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  </Stack>
);
Toaster.propTypes = propTypes;
Toaster.defaultProps = defaultProps;
export default Toaster;
