import { gql } from '@apollo/client';

const ADD_USER = gql`
mutation Mutation($user: registrationFields) {
    createUser(user: $user) {
      id
      first_name
      last_name
      email
      status
      details
      set_status_on
      set_status
      status_emoji
      agora_id
      google_token
      is_google_control
      created_by
      updated_by
      created_at
      updated_at
      is_active
      phone_number
      phone_country_code
      aws_cognito_username
    }
  }
`;

export default ADD_USER;
