import { gql } from '@apollo/client';

const GET_USERS = gql`
query GetUsers {
    getUsers {
      id
      first_name
      last_name
      email
    }
  }
`;

export default GET_USERS;
