import { gql } from '@apollo/client';

const EDIT_USER = gql`
  mutation Mutation($user: updateUserDetailsInput) {
    updateUserDetails(user: $user) {
      id
      first_name
      last_name
      email
      phone_number
      phone_country_code
      is_active
    }
  }
  `;

export default EDIT_USER;
