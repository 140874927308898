import { gql } from '@apollo/client';

const ADD_MEMBERS = gql`
mutation AssignUser($input: UserTeamInput) {
  assignUser(input: $input) {
    id
    user_id
    team_id
    created_by
  }
}
`;

export default ADD_MEMBERS;
