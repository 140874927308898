/* eslint-disable max-len */
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/system';
import { decodeToken } from 'react-jwt';
import { TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AUTH_TOKEN, USER_ID, REFRESH_TOKEN, EXPIRES_IN } from '../../constants/common';
import logo from '../../assets/svgs/logo.svg';
import '../../styles/login.css';
import ADMIN_LOGIN from './loginGraphQuery';
import AuthContext from '../../context/AuthContext';

const StyledButton = styled(Button)`
  &.MuiButton-root {
    font-family: Lato;
    font-weight: 500;
    font-size: 16px;
    color: #007AFF;
    text-transform: none;
    border-radius: 95px; 
    width: 318px;
    height: 56px;
  }
`;

const CustomGrid = styled(Grid)`
&.MuiGrid-root {
  display: grid;
  place-items:  center;
}
`;

const CustomInputLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    font-family: Lato;
    font-size: 14px;
    color: #A8AEB8;
}
`;

const SignInTypography = styled(Typography)`
&.MuiTypography-root {
  font-size: 35px;
    font-family: Lato;
    font-weight: 300;
    margin-bottom: 40px;
}
`;

const AdminTypography = styled(Typography)`
&.MuiTypography-root {
    font-size: 9px;
    font-family: Lato;
    font-weight: 900;
    color: #DA2C4C;
    margin-bottom: 40px;
    margin-top: 12px;
}
`;
const CustomTextField = styled(TextField)`
&.MuiTextField-root {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  color: #1A191A;
  background-color: #FFFFFF;
  text-transform: none;
  width: 80%;
}
`;
const CustomAlert = styled(Alert)`
&.MuiAlert-root {
  font-size: 16px;
  font-family: Lato;
  font-style: normal;
  color: white;
  background-color: #EF3644;
  max-width: 376px;
  width: 100%;
  height: 45px;
  justify-content: center;
  border-radius: unset;
}
`;

const useStyles = makeStyles({
  input: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
});

const Login = () => {
  const { dispatch } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const expireTime = localStorage.getItem(EXPIRES_IN);
  const ref = React.useRef(null);
  const classes = useStyles();
  const [login] = useLazyQuery(ADMIN_LOGIN, { fetchPolicy: 'no-cache',
    variables: {
      username: emailValue,
      password: passwordValue,
    },
  });
  const date = new Date();
  const expire = new Date(expireTime).getTime();
  if (date.getTime() >= expire) {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(EXPIRES_IN);
  }

  const loginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const initialValues = {
    email: emailValue,
    password: passwordValue,
  };

  return (
    <Grid width="100%" style={{ maxWidth: '376px', margin: '0 auto', height: '100vh', overflowY: 'scroll', scrollbarWidth: 'none' }}>
      <Grid
        sx={{ marginTop: '64px' }}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <img src={logo} alt="spring logo" sx={{ marginBottom: '12px', width: '50px', height: '54px' }} />
        <AdminTypography>ADMIN</AdminTypography>
        <SignInTypography>Sign In</SignInTypography>
      </Grid>
      <Grid>
        <Formik
          innerRef={ref}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={async (values) => {
            try {
              localStorage.removeItem(AUTH_TOKEN);
              localStorage.removeItem(USER_ID);
              localStorage.removeItem(REFRESH_TOKEN);
              localStorage.removeItem(EXPIRES_IN);

              setEmailValue(values.email);
              setPasswordValue(values.password);
              const { data, error } = await login();
              if (data) {
                const Token = decodeToken(data.login.idToken);
                if (Token['cognito:groups']) {
                  const authToken = data.login.idToken;
                  const userID = data.login.userId;
                  const { refreshToken } = data.login;
                  localStorage.setItem(AUTH_TOKEN, authToken);
                  localStorage.setItem(USER_ID, userID);
                  localStorage.setItem(REFRESH_TOKEN, refreshToken);
                  const expireDate = new Date();
                  expireDate.setSeconds(expireDate.getSeconds() + parseInt(data.login.expiresIn, 10));
                  localStorage.setItem(EXPIRES_IN, expireDate);
                  await dispatch({ type: 'AUTH_TOKEN', payload: authToken });
                  navigate('/users');
                }
                setLoginError(true);
              }
              if (error) {
                const passwordMessage = error.networkError.result.errors.map((err) => err.message).toString().slice(0, 21);
                const result = (passwordMessage) === ('Password is incorrect');
                if (result) {
                  setErrorMsg('Password is incorrect');
                  setLoginError(true);
                } else {
                  setErrorMsg('No Spring account with that email');
                  setLoginError(true);
                }
              }
            } catch (e) {
              setErrorMsg('Something went wrong!');
              localStorage.removeItem(AUTH_TOKEN);
              localStorage.removeItem(USER_ID);
              localStorage.removeItem(REFRESH_TOKEN);
              localStorage.removeItem(EXPIRES_IN);
              setLoginError(true);
            }
          }}
        >
          {({ touched, errors, values, handleBlur, handleChange, handleSubmit }) => (
            <Grid>
              <Grid>
                <Box sx={{ borderBottom: 1, borderTop: 1, borderColor: '#E4E6E9', marginTop: '40px' }}>
                  <Grid sx={{ display: 'flex', marginBottom: '10px', marginLeft: '10px' }} className={classes.input}>

                    <CustomInputLabel sx={{ mr: 10, my: 0.5, marginRight: '58px', marginTop: '20px' }}>Email</CustomInputLabel>
                    <CustomTextField
                      required
                      style={{ width: '70%' }}
                      type="email"
                      name="email"
                      id="email"
                      error={errors.email && touched.email}
                      helperText={touched.email && errors.email}
                      value={values.email}
                      onChange={(e) => {
                        handleChange(e);
                        setLoginError(false);
                      }}
                      onBlur={handleBlur}
                      disableUnderline
                    />

                  </Grid>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: '#E4E6E9' }}>
                  <Grid sx={{ display: 'flex', marginBottom: '10px', marginLeft: '10px' }} className={classes.input}>

                    <CustomInputLabel sx={{ mr: 10, my: 0.5, marginRight: '32px', marginTop: '20px' }}>Password</CustomInputLabel>
                    <CustomTextField
                      required
                      style={{ width: '70%' }}
                      type="password"
                      name="password"
                      id="password"
                      error={errors.password && touched.password}
                      helperText={touched.password && errors.password}
                      value={values.password}
                      onChange={(e) => {
                        handleChange(e);
                        setLoginError(false);
                      }}
                      onBlur={handleBlur}
                      disableUnderline
                    />

                  </Grid>
                </Box>

              </Grid>
              <CustomGrid sx={{ marginBottom: '33px', width: '100%' }}>
                {loginError && <CustomAlert icon={false} severity="error">{errorMsg}</CustomAlert>}
              </CustomGrid>
              <Grid
                mt={4}
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <StyledButton style={{ backgroundColor: 'rgb(0, 122, 255, 0.1)' }} onClick={() => handleSubmit()}>Sign In</StyledButton>
              </Grid>

            </Grid>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};
export default Login;
