import * as React from 'react';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useLazyQuery } from '@apollo/client';
import { Divider, InputLabel, ListItemButton } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import GET_TEAMS from './getTeamGraphQuery';
import AuthContext from '../../../context/AuthContext';
import LoadingOverlay from '../../LoadingOverlay';

const CustomLabel = styled(InputLabel)`
&.MuiInputLabel-root{
  color: #757575;
  font-family: Lato;
  font-size: 13px;
  font-weight: 400;
}
`;

const TeamList = () => {
  const { dispatch } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [Loading, setIsLoading] = React.useState(false);
  const [getTeams] = useLazyQuery(GET_TEAMS, { fetchPolicy: 'no-cache' });
  const [teams, setTeams] = React.useState([]);
  React.useEffect(() => {
    setIsLoading(true);
    getTeams().then((e) => setTeams(e.data.getTeams))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleClick = (value) => async () => {
    await dispatch({ type: 'ADD_TEAM_MEMBER', payload: '' });
    await dispatch({ type: 'EDIT_TEAM', payload: [] });
    await dispatch({ type: 'ADD_MEMBER', payload: [] });
    await dispatch({ type: 'TAB_VALUE', payload: 0 });
    navigate('/edit-team', { state: { teamId: value.id, path: 'user' } });
  };
  return (
    <>
      <LoadingOverlay open={Loading} />
      <List
        sx={{
          overflow: 'auto',
          width: '100%',
        }}
      >
        {teams && teams
          .map((row) => (
            <>
              <ListItemButton onClick={handleClick(row)}>
                <ListItemText style={{ marginLeft: '-10px' }} primary={`${row.name}`} />
                <CustomLabel>{row.teamMembersCount}</CustomLabel>
              </ListItemButton>
              <Divider />

            </>
          ))}
      </List>

    </>
  );
};

export default TeamList;
