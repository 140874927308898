/* eslint-disable max-len */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import { AUTH_TOKEN } from '../../constants/common';
import routesArray from './routesArray';
import Error404 from '../../pages/Error/Error404';
import BasicModal from './sessionExpire';

const propTypes = {
  children: PropTypes.string,
  redirectTo: PropTypes.string,
};

const defaultProps = {
  children: '',
  redirectTo: '',
};

const RequireAuth = ({ children, redirectTo }) => {
  const idToken = localStorage.getItem(AUTH_TOKEN);
  const idTokenData = jwtDecode(idToken);
  const currentTime = Math.floor(Date.now() / 1000);
  if (currentTime >= idTokenData.exp) {
    return (
      <BasicModal />
    );
  }
  const isAuthenticated = localStorage.getItem(AUTH_TOKEN);
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

const RouteList = () => (
  <Routes>
    {routesArray.map((route) => (route.protected ? (
      <Route
        exact
        path={route.path}
        element={(
          <RequireAuth redirectTo="/">
            {route.element}
          </RequireAuth>
        )}
        key={route.path}
      />
    ) : (
      <Route exact path={route.path} element={route.element} key={route.path} />
    )))}
    <Route path="*" element={<Error404 />} />
  </Routes>

);
RequireAuth.defaultProps = defaultProps;
RequireAuth.propTypes = propTypes;
export default RouteList;
