import React, { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

const AuthContext = createContext({});

const initialState = {
  addTeam: [],
  editUser: [],
  tabValue: 0,
  addUser: {},
  removeUser: {},
  addTeamMember: '',
  addMember: [],
  authUser: {},
  authToken: '',
  editTeam: [],
  isLoading: true,
  expireIn: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TEAM': {
      return { ...state, addTeam: action.payload };
    }
    case 'EDIT_USER': {
      return { ...state, editUser: action.payload };
    }
    case 'TAB_VALUE': {
      return { ...state, tabValue: action.payload };
    }
    case 'ADD_USER': {
      return { ...state, addUser: action.payload };
    }
    case 'EDIT_TEAM': {
      return { ...state, editTeam: action.payload };
    }
    case 'ADD_TEAM_MEMBER': {
      return { ...state, addTeamMember: action.payload };
    }
    case 'ADD_MEMBER': {
      return { ...state, addMember: action.payload };
    }
    case 'USER_PROFILE': {
      return { ...state, authUser: action.payload };
    }
    case 'AUTH_TOKEN': {
      return { ...state, authToken: action.payload };
    }
    case 'LOADING': {
      return { ...state, isLoading: action.payload };
    }
    case 'EXPIREIN': {
      return { ...state, expireIn: action.payload };
    }
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [{ tabValue,
    isLoading,
    authUser,
    editUser,
    addTeamMember,
    addUser,
    addTeam,
    editTeam,
    addMember,
    authToken,
    expireIn },
  dispatch] = useReducer(reducer, initialState);

  const values = useMemo(() => ({
    tabValue,
    isLoading,
    authUser,
    editUser,
    addTeamMember,
    addUser,
    addTeam,
    editTeam,
    addMember,
    authToken,
    expireIn,
    dispatch,
  }));
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
export default AuthContext;
