import React from 'react';
import styled from 'styled-components';
import Backdrop from '@mui/material/Backdrop';
import { palette } from 'styled-theme';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

const propTypes = {
  open: PropTypes.bool.isRequired,
};

const PageInner = styled.div`
  display: flex;
  align-items: center;
  width: 74px;
  height: 74px;
  position: relative;
  justify-content: center;
  background-color: ${palette('grayscale', 5)};
  border-radius: 12px;
  font-size: 15px;
`;
const Logo = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    display: block;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    position: absolute;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
  }
`;

const LoadingOverlay = ({ open }) => (
  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
    <PageInner>
      <Logo>
        <CircularProgress />
      </Logo>
    </PageInner>
  </Backdrop>
);

LoadingOverlay.propTypes = propTypes;

export default LoadingOverlay;
